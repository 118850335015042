import { Button } from '@repo/ui/components/Button.js'
import {
  type ComponentProps,
  type MouseEvent,
  useCallback,
  useState
} from 'react'

export const RateLimitedButton = ({
  onClick,
  limitInMs,
  ...props
}: ComponentProps<typeof Button> & { limitInMs: number }) => {
  const [isDisabled, setIsDisabled] = useState(false)

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      if (!isDisabled) {
        onClick?.(e)
        setIsDisabled(true)
        setTimeout(() => {
          setIsDisabled(false)
        }, limitInMs)
      }
    },
    [isDisabled, onClick, limitInMs]
  )

  return <Button onClick={handleClick} {...props} disabled={isDisabled} />
}
