import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@repo/ui/components/AlertDialog.js'
import { Button } from '@repo/ui/components/Button.js'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/Dialog.js'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { Input } from '@repo/ui/components/Input.js'
import { Label } from '@repo/ui/components/Label.js'
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@repo/ui/components/Select.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import { useState } from 'react'
import { Form } from '~/components/form/Form'
import { useForm } from '~/hooks/useForm'
import { DeleteSessionSchema, ReportSessionIssueSchema, type Session } from './routeConfig'

export const SessionActions = ({ session }: { session: Session }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)

  const [reportIssueForm, reportIssueFields] = useForm({
    schema: ReportSessionIssueSchema,
    fetcherKey: `${ReportSessionIssueSchema.shape.intent.value}_${session.id}`,
    defaultValue: {
      sessionId: session.id,
      vendorName: session.vendor.name,
      productName: session.product.name
    },
    onSuccess: () => {
      setDialogOpen(false)
      setMenuOpen(false)
    }
  })

  const [deleteForm, deleteFields] = useForm({
    schema: DeleteSessionSchema,
    fetcherKey: `${DeleteSessionSchema.shape.intent.value}_${session.id}`,
    defaultValue: { sessionId: session.id }
  })

  return (
    <>
      <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" icon="dots-vertical" size="icon" className="size-6" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem onSelect={() => setDialogOpen(true)} icon="warning">
            Report issue
          </DropdownMenuItem>

          <DropdownMenuItem onSelect={() => setDeleteAlertOpen(true)} icon="trash-can">
            Delete session
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Report an issue</DialogTitle>
            <DialogDescription>
              Let us know if something doesn't look right with this session.
            </DialogDescription>
          </DialogHeader>

          <Form form={reportIssueForm} action="/?index">
            <Form.HiddenInput name={reportIssueFields.sessionId.name} />
            <div className="grid grid-cols-2 gap-4">
              <Form.Field name={reportIssueFields.vendorName.name}>
                <Form.Label>Vendor</Form.Label>
                <Form.Input readOnly />
              </Form.Field>
              <Form.Field name={reportIssueFields.productName.name}>
                <Form.Label>Product</Form.Label>
                <Form.Input readOnly />
              </Form.Field>
              <div className="col-span-2 space-y-4">
                <fieldset className="space-y-1">
                  <Label>User</Label>
                  <Input readOnly value={session.employee.displayName} />
                </fieldset>

                <Form.Field name={reportIssueFields.category.name}>
                  <Form.Label>Category</Form.Label>
                  <Form.Select required>
                    <SelectTrigger>
                      <SelectValue placeholder="Select an option..." />
                    </SelectTrigger>
                    <SelectContent>
                      {ReportSessionIssueSchema.shape.category.options.map((option) => (
                        <SelectItem key={option} value={option}>
                          {option}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Form.Select>
                  <Form.FieldErrors />
                </Form.Field>

                <Form.Field name={reportIssueFields.notes.name}>
                  <Form.Label>Notes</Form.Label>
                  <Form.Textarea rows={2} className="min-h-0" />
                  <Form.FieldErrors />
                </Form.Field>
              </div>
            </div>

            <Spacer size="sm" />

            <Form.SubmitButton>Submit</Form.SubmitButton>
          </Form>
        </DialogContent>
      </Dialog>

      <AlertDialog open={deleteAlertOpen} onOpenChange={setDeleteAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete session?</AlertDialogTitle>
            <AlertDialogDescription />
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel />
            <Form form={deleteForm} action="/?index">
              <Form.HiddenInput name={deleteFields.sessionId.name} />
              <Form.SubmitButton variant="destructive">Delete</Form.SubmitButton>
            </Form>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
