import { useRevalidator } from '@remix-run/react'
import { useCallback, useEffect, useState } from 'react'
import type { Session } from './routeConfig'

export const useLiveRefresh = ({ sessions }: { sessions: Session[] }) => {
  const [seenIds, setSeenIds] = useState<Set<Session['id']>>(
    new Set([...sessions.map((d) => d.id)])
  )
  const [newIds, setNewIds] = useState<Session['id'][]>([])

  const revalidator = useRevalidator()

  const handleRevalidation = useCallback(
    (data: Session[]) => {
      const newIds: Session['id'][] = []

      for (const row of data) {
        if (seenIds.has(row.id)) continue
        seenIds.add(row.id)
        newIds.push(row.id)
      }

      if (newIds.length) {
        setNewIds(newIds)
        setSeenIds(new Set(seenIds))
        setTimeout(() => {
          setNewIds([])
        }, 300)
      }
    },
    [seenIds]
  )

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!navigator.onLine) return
      if (typeof document === 'undefined') return
      if (!document) return
      if (document.visibilityState !== 'visible') return
      revalidator.revalidate()
    }, 30 * 1000) // 30 seconds

    // Revalidate when the page becomes visible again
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && navigator.onLine) {
        revalidator.revalidate()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      clearInterval(intervalId)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [revalidator])

  useEffect(() => {
    handleRevalidation(sessions)
  }, [sessions, handleRevalidation])

  return {
    data: sessions,
    newSessionIds: newIds,
    refresh: revalidator.revalidate,
    status: revalidator.state
  }
}
