import { useLoaderData } from '@remix-run/react'
import { Icon } from '@repo/ui/components/Icon.js'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@repo/ui/components/Select.js'
import { Statistic } from '~/components/Statistic'
import { useTypedSearchParam } from '~/hooks/useTypedSearchParam'
import type { loader } from './route'
import { FilterKeys, PeriodEnum } from './routeConfig'

export const SessionStatistics = () => {
  const { statistics } = useLoaderData<typeof loader>()

  const param = useTypedSearchParam(FilterKeys.enum.period, {
    serializeSchema: PeriodEnum,
    deserializeSchema: PeriodEnum.catch('1')
  })

  return (
    <div className="space-y-2 bg-secondary p-4 rounded-lg border">
      <Select defaultValue={param.value} onValueChange={param.set}>
        <SelectTrigger className="w-fit text-xs font-medium py-1 h-8">
          <Icon name="calendar" size="sm" />
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {PeriodEnum.options.map((option) => (
            <SelectItem key={option} value={option}>
              {option === '1'
                ? 'Today'
                : option === '7'
                  ? 'Last week'
                  : 'Last 30 days'}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div className="grid gap-6 auto-cols-auto grid-flow-col">
        {statistics.map((stat) => (
          <Statistic
            key={stat.label}
            label={stat.label}
            icon={stat.icon}
            value={stat.value}
          />
        ))}
      </div>
    </div>
  )
}
