import { selectSessionSchema, selectVendorSchema } from '@repo/db/schema'
import { z } from 'zod'
import { TableParamsSchema } from '~/utils/table'
import type { getSessionsByOrgId } from './getSessionsByOrgId.server'

export type Session = Awaited<ReturnType<typeof getSessionsByOrgId>>[number]

export const DeleteSessionSchema = z.object({
  intent: z.literal('deleteSession'),
  sessionId: selectSessionSchema.shape.id
})

export const ReportSessionIssueSchema = z.object({
  intent: z.literal('reportSessionIssue'),
  sessionId: selectSessionSchema.shape.id,
  vendorName: selectVendorSchema.shape.name,
  productName: selectVendorSchema.shape.name,
  category: z.enum([
    `This session should not have been tracked`,
    `Something's wrong with the vendor or product`,
    `Other`
  ]),
  notes: z.string().optional()
})

export const FilterKeys = z.enum(['period'])

export const SortableColumns = z.enum(['date'])

export const PeriodEnum = z.enum(['1', '7', '30'])

export const QueryParamsSchema = z.object({
  sortBy: SortableColumns.catch('date'),
  order: TableParamsSchema.shape.order.catch('desc'),
  page: TableParamsSchema.shape.page,
  pageSize: TableParamsSchema.shape.pageSize.default(50),
  query: TableParamsSchema.shape.query,
  period: PeriodEnum.catch('1')
})
